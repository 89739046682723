<template>
	<div class="container">
		<div class="header" @click="expanded = !expanded">
			<h3>{{ header }}</h3>
			<div class="expand-icon dropdown-arrow">
				<font-awesome-icon :icon="expanded ? 'fal fa-chevron-up' : 'fal fa-chevron-down'" size="1x" />
			</div>
		</div>
		<slide-up-down v-model="expanded" :duration="400">
			<div class="content">
				<div v-parse-links v-html="content" />
			</div>
		</slide-up-down>
	</div>
</template>

<script setup>
defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});

const expanded = ref(false);
</script>

<style lang="scss" scoped>
.header,
.content {
	width: 100%;
	padding: 16px 26px;
	text-align: left;
}

.container {
	text-align: left;
	background-color: var(--intro-block-background-color);
	border-radius: 6px;
	overflow: hidden;
	margin: 15px 0;

	.header {
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #fff;

		.expand-icon {
			transition: all 0.3s ease-in-out;
			color: var(--body-color);
			font-size: 20px;
		}

		h3 {
			margin: 0;
			font-size: 1.1em;
			font-family: var(--body-font-family);
			font-weight: 500;
			color: var(--body-color);
			width: 100%;
			max-width: calc(100% - 40px);
			text-align: left;
		}
	}

	.content {
		border-top: none;
		background-color: var(--intro-block-background-color);
		min-height: 120px;

		ul,
		ol {
			padding: 0 0 0 20px;
		}

		:deep(:first-child) {
			margin-top: 0;
		}

		:deep(:last-child) {
			margin-bottom: 0;
		}
	}
}
</style>
